import firebase from 'firebase/app'
import React from 'react'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'

import config from 'config'

import { API_HOST } from '../../../firebaseconfig.js'

const provider = new firebase.auth.GoogleAuthProvider()

export * from './components'

export interface Claims {
  isGammaAdmin: boolean
}
export interface AuthContextType {
  user: firebase.User | null
  claims?: Claims | null
  customToken?: string | null
}
export const AuthContext = React.createContext<AuthContextType>({
  user: null,
  claims: null,
})
export const AuthProvider = AuthContext.Provider
export const AuthConsumer = AuthContext.Consumer

export const signinWithGoogle = () => {
  return firebase
    .auth()
    .signInWithPopup(provider)
    .then((result) => {
      // User is signed in. Get the ID token.
      if (!result.user) {
        throw new Error('Signin returned no user.')
      }
      return result.user.getIdToken()
    })
    .then((token) => {
      // eslint-disable-next-line
      return fetch(API_HOST + '/auth/v1/setCustomClaims', {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idToken: token }),
      })
    })
    .then((response) => response.json())
    .then(({ status }) => {
      console.log('[useUser] Claim refresh status: ', status)
    })
    .catch(function (error) {
      console.error('[useUser] Error with google auth: ', error)
    })
}

export const signInAnonymously = () =>
  firebase
    .auth()
    .signInAnonymously()
    .catch(function (error) {
      // Handle Errors here.
      const errorMessage = error.message
      console.warn('[useUser] error signing in anonymously: ', errorMessage)
    })

export const signOut = () =>
  firebase
    .auth()
    .signOut()
    .then(function () {
      console.log('[useUser] User manually signed out.')
    })
    .catch(function (error) {
      console.warn('[useUser] Error on sign out.')
    })

export const useUser = (): [
  firebase.User | null,
  Claims | null | undefined,
  boolean,
  boolean
] => {
  const { user, claims, customToken }: AuthContextType = React.useContext<
    AuthContextType
  >(AuthContext)
  const ready = !!user && !!claims && !!customToken
  const isGammaAdmin = !!claims && !!claims.isGammaAdmin
  return [user, claims, ready, isGammaAdmin]
}

export const useAuth = (firebaseReady: boolean): AuthContextType => {
  const [user, setUser] = React.useState<firebase.User | null>(null)
  const [claims, setClaims] = React.useState<Claims | null>(null)
  const [customToken, setCustomToken] = React.useState<string | null>(null)
  let callback: any = null
  let metadataRef: any = null

  React.useEffect(() => {
    if (!firebaseReady) return
    // Listen for signin state change
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        const uid = user.uid
        console.log('[useUser] User signed in: ', user.email || uid)
        // Remove previous listener.
        if (callback) {
          metadataRef.off('value', callback)
        }

        fetch(API_HOST + '/auth/v1/getCustomToken', {
          mode: 'cors',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uid }),
        })
          .then((res) => res.json())
          .then(({ customToken }) => {
            setCustomToken(customToken)
            const cookieDomain = config('IS_PROD')
              ? '.gamma.app'
              : window.location.hostname
            const expires = new Date()
            expires.setTime(expires.getTime() + 1 * 3550 * 1000)
            console.log(
              `[getCustomToken] Success. Setting on ${cookieDomain} domain`
            )
            document.cookie = `gammaAuthToken=${customToken}; domain=${cookieDomain}; path=/; expires=${expires.toUTCString()}`
          })
          .catch((err) => {
            console.warn('[getCustomToken] error', { err })
            setCustomToken('none(error)')
          })
        // On user login add new listener.
        if (user) {
          // Check if refresh is required.
          metadataRef = firebase
            .database()
            .ref('metadata/' + user.uid + '/refreshTime')
          callback = () => {
            // Force refresh to pick up the latest custom claims changes.
            // Note this is always triggered on first call. Further optimization could be
            // added to avoid the initial trigger when the token is issued and already contains
            // the latest claims.
            user.getIdTokenResult(true).then((res) => {
              setClaims(res.claims as Claims)
            })
          }
          // Subscribe new listener to changes on that node.
          metadataRef.on('value', callback)
        }
      } else {
        console.log('[useUser] User was signed out. Signin in as anonymous')
        setClaims(null)
        signInAnonymously()
      }
      setUser(user)
    })
  }, [firebaseReady])
  React.useEffect(() => {
    if (!user || !firebaseReady) return
    return setupPresenceForUser(user)
  }, [user, firebaseReady])

  return { user, claims, customToken }
}

/**
 * Setup presence via realtime database.
 * See https://firebase.google.com/docs/firestore/solutions/presence
 */
const setupPresenceForUser = (user: firebase.User) => {
  // Create a reference to this user's specific status node.
  // This is where we will store data about being online/offline.
  const userStatusDatabaseRef = firebase.database().ref('/status/' + user.uid)

  // We'll create two constants which we will write to
  // the Realtime database when this device is offline
  // or online.
  const isOfflineForDatabase = {
    state: 'offline',
    last_changed: firebase.database.ServerValue.TIMESTAMP,
  }

  const isOnlineForDatabase = {
    state: 'online',
    last_changed: firebase.database.ServerValue.TIMESTAMP,
  }

  // Create a reference to the special '.info/connected' path in
  // Realtime Database. This path returns `true` when connected
  // and `false` when disconnected.
  firebase
    .database()
    .ref('.info/connected')
    .on('value', (snapshot: firebase.database.DataSnapshot) => {
      // If we're not currently connected, don't do anything.
      if (snapshot.val() == false) {
        return
      }

      // If we are currently connected, then use the 'onDisconnect()'
      // method to add a set which will only trigger once this
      // client has disconnected by closing the app,
      // losing internet, or any other means.
      userStatusDatabaseRef
        .onDisconnect()
        .set(isOfflineForDatabase)
        .then(function () {
          // The promise returned from .onDisconnect().set() will
          // resolve as soon as the server acknowledges the onDisconnect()
          // request, NOT once we've actually disconnected:
          // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

          // We can now safely set ourselves as 'online' knowing that the
          // server will mark us as offline once we lose connection.
          userStatusDatabaseRef.set(isOnlineForDatabase)
        })
    })

  const userStatusFirestoreRef = firebase.firestore().doc('/status/' + user.uid)

  // Firestore uses a different server timestamp value, so we'll
  // create two more constants for Firestore state.
  const isOfflineForFirestore = {
    state: 'offline',
    last_changed: firebase.firestore.FieldValue.serverTimestamp(),
  }

  const isOnlineForFirestore = {
    state: 'online',
    last_changed: firebase.firestore.FieldValue.serverTimestamp(),
  }

  firebase
    .database()
    .ref('.info/connected')
    .on('value', (snapshot: firebase.database.DataSnapshot) => {
      if (snapshot.val() == false) {
        // Instead of simply returning, we'll also set Firestore's state
        // to 'offline'. This ensures that our Firestore cache is aware
        // of the switch to 'offline.'
        userStatusFirestoreRef.set(isOfflineForFirestore)
        return
      }

      userStatusDatabaseRef
        .onDisconnect()
        .set(isOfflineForDatabase)
        .then(function () {
          userStatusDatabaseRef.set(isOnlineForDatabase)

          // We'll also add Firestore set here for when we come online.
          userStatusFirestoreRef.set(isOnlineForFirestore)
        })
    })

  return () => {
    firebase.database().ref('.info/connected').off('value')
  }
}

import firebase from 'firebase'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import {
  uniqueNamesGenerator,
  adjectives,
  animals,
} from 'unique-names-generator/dist/index'

import { UserInfo } from '@gammatech/ottype-deck'
interface UserStatusRefs {
  [key: string]: () => void
}
interface UsersStatus {
  [key: string]: {
    state: 'online' | 'offline'
  }
}

export interface UsersDoc {
  [key: string]: UserInfo
}

const colorRange = [
  '#008080', // teal
  '#0000ff', // blue
  '#ff0000', // red
  '#008000', // green
  '#7fff00', // chartreuse
  '#ffa500', // orange
  '#ffc0cb', // pink
  '#800080', // purple
  '#dc143c', // crimson
  '#ee82ee', // violet
  '#483d8b', // darkslateblue
]
export const getUserColor = (userId: string) => {
  const uidNumber =
    userId
      .split('')
      .map((i) => i.charCodeAt(0))
      .reduce((acc, i) => i + acc, 0) % 10

  return colorRange[uidNumber]
}

export type DisplayNameUser = {
  uid?: string
  displayName?: string
}

export const useDisplayName = (user: DisplayNameUser | null) => {
  const [name, setName] = useState('')

  useEffect(() => {
    if (!user) return
    if (!user.uid) {
      console.warn('[useDisplayName] NO UID???', { user })
      setName('No user.uid :(')
      return
    }
    if (user.displayName) {
      setName(user.displayName)
      return
    }
    // Convert the UID to a number (deterministically)
    const seed = user.uid
      .split('')
      .map((i) => i.charCodeAt(0))
      .reduce((acc, i) => i + acc, 0)
    setName(
      uniqueNamesGenerator({
        dictionaries: [adjectives, animals],
        style: 'capital',
        separator: ' ',
        seed,
      })
    )
  }, [user])
  return name
}

export const useScrollNavigate = (deck: any, name = '') => {
  const handleScroll = useCallback(
    (e: any) => {
      const top = e.target.scrollTop === 0
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight

      console.log({ top, bottom, name })
      if (top) {
        deck.up()
      } else if (bottom) {
        deck.down()
      }
    },
    [deck]
  )
  return handleScroll
}

export const useSlideState = (deck: any, ref: any) => {
  const [returnTo, setReturnTo] = useState<number[]>([0])
  const [isVisible, setVisible] = useState(false)
  const [indices, setIndices] = useState({ indexh: null, indexv: null })
  const handleSlideChanged = useCallback(
    (event: any) => {
      // event.previousSlide, event.currentSlide, event.indexh, event.indexv
      const visible =
        event.indexh === indices.indexh &&
        (indices.indexv !== -1 ? event.indexv === indices.indexv : true)
      setVisible(visible)
      if (event.previousSlide) {
        const previousSlideH = Number(
          event.previousSlide.getAttribute('data-index-h')
        )
        const previousSlideV = Number(
          event.previousSlide.getAttribute('data-index-v')
        )
        setReturnTo([previousSlideH, previousSlideV])
      }
    },
    [indices]
  )
  useEffect(() => {
    if (!deck || !ref) return
    deck.on('slidechanged', handleSlideChanged)
    return () => {
      deck.off('slidechanged', handleSlideChanged)
    }
  }, [deck, ref, handleSlideChanged])
  useEffect(() => {
    if (!deck || !ref) return
    const thisSlideH = Number(ref.current.getAttribute('data-index-h'))
    const thisSlideV = Number(ref.current.getAttribute('data-index-v'))
    const vals = { indexh: thisSlideH, indexv: thisSlideV }
    setIndices(vals)
  }, [deck, ref])
  return [isVisible, returnTo, indices]
}

export const useUsersPresentationState = (
  presentationId: string,
  currentUserId: string = ''
) => {
  const userStatusRefs = React.useRef<UserStatusRefs>({})
  const usersRTDBRef = React.useRef<firebase.database.Reference>()
  const [users, setUsers] = React.useState<UsersDoc>({}) // cursor position
  const [usersStatus, setUsersStatus] = React.useState<UsersStatus>({}) // online/offline

  useEffect(() => {
    usersRTDBRef.current = firebase
      .database()
      .ref(`/presentations/${presentationId}/users`)

    const callback = (snapshot: firebase.database.DataSnapshot) => {
      const snapshotUsers: UsersDoc = snapshot.val()
      // console.warn("/users update (likely cursor moving): ", snapshotUsers);
      setUsers(snapshotUsers)
    }
    usersRTDBRef.current.on('value', callback, (err: any) => {
      console.warn(`Error subscribing to users doc ${presentationId}.`)
    })
    // Invoke all unsub functions when we unmount
    return () => {
      Object.entries(userStatusRefs.current).forEach(([key, unsub]) => {
        unsub()
      })
      usersRTDBRef.current.off('value', callback)
    }
  }, [])
  useEffect(
    /**
     * When users join the presentation, make sure we have a ref
     * to their status/{userId} in RealtimeDB so we can monitor
     * their online/offline status.
     */
    () => {
      Object.entries(users).forEach(([id, user]: [string, UserInfo]) => {
        if (!userStatusRefs.current[id]) {
          const callback = (snapshot: firebase.database.DataSnapshot) => {
            const data = snapshot.val()
            // console.log(`/status/${id} change: ${data}`);
            setUsersStatus((prev) => ({
              ...prev,
              [id]: data,
            }))
          }
          firebase.database().ref(`status/${id}`).on('value', callback)
          userStatusRefs.current[id] = () => {
            firebase.database().ref(`status/${id}`).off('value', callback)
          }
        }
      })
    },
    [users]
  )

  return [users, usersStatus]
}

export const API_HOST =
  process.env.NODE_ENV === 'development'
    ? 'https://gamma-6b251-dev.web.app'
    : 'https://gamma-6b251-dev.web.app'

export const APP_HOST =
  process.env.NODE_ENV === 'development'
    ? 'https://gamma-6b251-dev.web.app'
    : 'https://gamma-6b251-dev.web.app'

export const CONVERGENCE_HOST =
  process.env.NODE_ENV === 'development'
    ? 'https://convergence.dev.gamma.app/api/realtime/convergence/default'
    : 'https://convergence.dev.gamma.app/api/realtime/convergence/default'

export const DEV_FIREBASE_CONFIG = {
  apiKey: 'AIzaSyD-tW2me4N-veFrBtc9l7I9_4UYoI0K_FI',
  authDomain: 'gamma-6b251-dev.firebaseapp.com',
  databaseURL: 'https://gamma-6b251-dev.firebaseio.com',
  projectId: 'gamma-6b251-dev',
  storageBucket: 'gamma-6b251-dev.appspot.com',
  messagingSenderId: '1039837819870',
  appId: '1:1039837819870:web:c9726a4bbc48f8a6ede34f',
  measurementId: 'G-EYBLL545E2',
}

export const ZOOM_PMIS = {
  james: '4602124507',
  jon: '5064726173',
  grant: '2909020717',
}

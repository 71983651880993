import { Avatar, AvatarBadge, Button, Flex, Spinner } from '@chakra-ui/react'
import React, { useCallback } from 'react'

import { useUser, signinWithGoogle, signOut } from '../index'

import { getUserColor } from 'modules/present/utils'

export const forAdminOnly = (Component: any, allowLocalhost = false) => (
  props
) => {
  const isLocalhost = /^localhost/.test(window.location.host)
  const [user, claims, ready, isGammaAdmin] = useUser()
  if ((!allowLocalhost || !isLocalhost) && !isGammaAdmin)
    return (
      <Flex align="center" justify="center" direction="column" height="100vh">
        {ready ? (
          <Flex direction="column">
            <h1>coming soon...</h1>
            <LoginOrLogoutButton />
          </Flex>
        ) : (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        )}
      </Flex>
    )
  return <Component {...props} />
}

export const UserAvatar = () => {
  const [user, claims, ready, isGammaAdmin] = useUser()
  const color = user ? getUserColor(user.uid) : '#c6c6c6'
  if (user && isGammaAdmin) {
    return (
      <Avatar
        name={(user && (user.displayName as string)) || ''}
        size="sm"
        border={`1px solid ${color}`}
        src={user.photoURL}
        backgroundColor={`${color}AB`}
      >
        <AvatarBadge boxSize="1.1em" bg={'green.500'} />
      </Avatar>
    )
  }
  return (
    <Avatar
      name="?"
      bg="grey"
      size="sm"
      border={`1px solid ${color}`}
      backgroundColor={`${color}AB`}
    >
      <AvatarBadge borderColor="darkgrey" bg="grey" boxSize="1.1em" />
    </Avatar>
  )
}

export const LoginOrLogoutButton = () => {
  const [user, claims, isReady, isGammaAdmin] = useUser()
  const isAuthed = user && !user.isAnonymous
  const onClick = useCallback(() => {
    if (isAuthed) {
      signOut().then(() => {
        console.log('[signOut] Success! Reloading the page')
        window.location.reload()
      })
    } else {
      signinWithGoogle().then(() => {
        console.log('[signinWithGoogle] Success! Reloading the page')
        window.location.reload()
      })
    }
  }, [isAuthed])

  if (!isReady) return null

  if (isAuthed) {
    return (
      <Button
        colorScheme="red"
        onClick={onClick}
        variant="solid"
        size="xs"
        isLoading={!user}
      >
        Logout
      </Button>
    )
  } else {
    return (
      <Button
        colorScheme="green"
        onClick={onClick}
        variant="solid"
        size="xs"
        isLoading={!user}
      >
        Login
      </Button>
    )
  }
}
